<template>
  <div class="attendance-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item :to="{ path: '/zuzhi/checkAttendance' }"
        >考勤情况</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{
          path: '/zuzhi/organizationList/myOrganizationList1',
          query: {
            oid: this.oid,
            fatherName: this.fatherName
          }
        }"
        >我的组织详情</el-breadcrumb-item
      >

      <el-breadcrumb-item>{{ fatherName }} </el-breadcrumb-item>
    </el-breadcrumb>

    <!-- <el-button
      style="margin-top: 0px;margin-bottom: 2px;font-size:12px;"
      @click="back"
      type="primary"
      icon="el-icon-arrow-left"
      >返回</el-button
    > -->
    <div id="frozen-btn">
      <button @click="back" class="green">
        返回
      </button>
    </div>
    <el-card class="course-attendance">
      <div class="course-title">{{ childName }}</div>
      <el-row :gutter="20">
        <el-col :span="24" style="height: 550px"
          ><div
            class="statistics-layout"
            style="height: 550px;border-radius: 8px;"
          >
            <div class="layout-title">考勤统计</div>
            <div style="padding: 5px">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div style="text-align: center">
                    <div style="color: black; font-size: 20px">
                      <i class="el-icon-user"></i>
                      课程总人数
                    </div>
                    <div
                      style="
                          color: #FF4500;
                          font-size:  28px;
                          font-weight:bold;
                          padding: 10px 0;
                          text-align: center;
                        "
                    >
                      {{ total }}
                    </div>
                  </div></el-col
                >
                <el-col :span="8">
                  <div style="text-align: center">
                    <div style="color: black; font-size: 20px">
                      <i class="el-icon-check"></i>
                      已签到人数
                    </div>
                    <div
                      style="
                          color: #FF4500;
                          font-size:  28px;
                          font-weight:bold;
                          padding: 10px 0;
                          text-align: center;
                        "
                    >
                      {{ signnum }}
                    </div>
                  </div></el-col
                >
                <el-col :span="8">
                  <div style="text-align: center">
                    <div style="color: black; font-size: 20px">
                      <i class="el-icon-close"></i>
                      未签到人数
                    </div>
                    <div
                      style="
                          color: #FF4500;
                          font-size: 28px;
                          font-weight:bold;
                          padding: 10px 0;
                          text-align: center;
                        "
                    >
                      {{ unsignnum }}
                    </div>
                  </div></el-col
                >
              </el-row>
            </div>
            <el-row :gutter="20">
              <el-col :span="16">
                <el-row :gutter="10">
                  <el-col :span="12">
                    <div ref="barChart" style="width: 100%;height: 400px"></div>
                  </el-col>
                  <el-col :span="12">
                    <div ref="pieChart" style="width: 100%; height: 350px;" />
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <div class="right-part">
                  <!-- <el-progress
                    v-if="attendanceRate >= 90"
                    type="circle"
                    :percentage="attendanceRate"
                    status="success"
                  ></el-progress>
                  <el-progress
                    v-else-if="attendanceRate >= 75"
                    type="circle"
                    :percentage="attendanceRate"
                  ></el-progress>
                  <el-progress
                    v-else-if="attendanceRate >= 60"
                    type="circle"
                    :percentage="attendanceRate"
                    status="warning"
                  ></el-progress>
                  <el-progress
                    v-else
                    type="circle"
                    :percentage="attendanceRate"
                    status="exception"
                  ></el-progress> -->

                  <el-select
                    v-model="selectedActivity"
                    clearable
                    placeholder="请选择时间进行统计"
                    style="margin-bottom:20px;"
                    size="mini"
                    @change="getAttendance"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>

                  <el-progress
                    type="dashboard"
                    :percentage="attendanceRate"
                    :color="colors"
                    style="margin-top:80px;"
                  ></el-progress>
                  <div v-if="attendanceRate >= 90" class="grade">
                    签到率<span class="grade-one">优秀</span>
                  </div>
                  <div v-else-if="attendanceRate >= 75" class="grade">
                    签到率<span class="grade-two">良好</span>
                  </div>
                  <div v-else-if="attendanceRate >= 60" class="grade">
                    签到率<span class="grade-three">合格</span>
                  </div>
                  <div v-else class="grade">
                    签到率<span class="grade-four">不合格</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div></el-col
        >
      </el-row>
    </el-card>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'courseAttendance',
  data() {
    return {
      ccid: '',
      oid: '',
      fatherName: '',
      childName: '',
      unsignnum: '', //未签到
      signnum: '', //签到
      total: '', //总人数
      chartData: {},
      attendanceRate: '',
      colors: [
        { color: '#ea5851', percentage: 60 },
        { color: '#daa44f', percentage: 75 },
        { color: ' #4c9df6', percentage: 90 },
        { color: ' #5fcb71', percentage: 100 }
      ],
      options: [],
      selectedActivity: ''
    }
  },
  computed: {
    pieData() {
      return [
        { name: '请假', value: this.chartData['请假'] },
        { name: '迟到', value: this.chartData['迟到'] },
        { name: '其他', value: this.chartData['其他'] },
        { name: '已签到', value: this.signnum },
        { name: '未签到', value: this.unsignnum }
      ]
    },
    updatedData() {
      this.getAttendance()
      return null
    }
  },
  created() {
    this.oid = this.$route.query.oid
    this.fatherName = this.$route.query.fatherName
    this.ccid = this.$route.query.ccid
    this.childName = this.$route.query.childName
    this.loadActivityOptions()
    this.drawBarChart()
    this.drawPieChart()
  },
  mounted() {
    this.getAttendance()
  },
  methods: {
    //获取actId
    async loadActivityOptions() {
      const { data: res } = await this.$http.get('/cou/findActByccid1', {
        params: {
          ccid: this.ccid
        }
      })
      // console.log('kkkkkkkkkkkkkk', res)
      if (res) {
        this.options = res.map(item => ({
          value: item.actId,
          label: item.createTime
        }))
      }
    },
    back() {
      this.$router.go(-1)
    },
    async getAttendance() {
      let params = {
        ccid: this.ccid
      }
      if (this.selectedActivity) {
        params.actid = this.selectedActivity
      }
      // 如果没有选择活动则不进行查询
      const { data: res } = await this.$http.get('/cou/statisticcourse', {
        params
      })
      if (res.code == 200) {
        this.chartData = res.data[0]
        this.total = res.data[0]['总人数']
        this.signnum = res.data[0]['签到']
        this.unsignnum = this.total - this.signnum
        // (this.signnum / this.total).toFixed() * 100
        if (this.total != 0) {
          this.attendanceRate = ((this.signnum / this.total) * 100).toFixed(2)
        } else {
          this.attendanceRate = 0
        }
        this.drawBarChart()
        this.drawPieChart()
      }
    },
    async drawBarChart() {
      let mybarChart = echarts.init(this.$refs.barChart)
      let option = {
        title: {
          text: '签到分布图', // 标题文本
          left: 'center', // 标题居中
          textStyle: {
            color: '#333', // 标题颜色
            fontSize: 18 // 标题字号
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: ['请假', '迟到', '旷课', '未认证', '其他', '已签到', '未签到'],
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: 'black' // x轴颜色
            }
          },
          axisLabel: {
            textStyle: {
              color: 'black' // x轴文字颜色
            },
            rotate: -45
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false // 隐藏y轴线条
          },
          axisTick: {
            show: false // 隐藏y轴刻度
          },
          splitLine: {
            lineStyle: {
              type: 'dashed' // y轴分割线类型
            }
          }
        },
        series: [
          {
            name: '人数',
            type: 'bar',
            barWidth: '60%', // 柱图宽度
            data: [
              this.chartData['请假'],
              this.chartData['迟到'],
              this.chartData['旷课'],
              this.chartData['未认证'],
              this.chartData['其他'],
              this.chartData['已签到'],
              this.unsignnum
            ], // 数据
            label: {
              show: true, // 显示标签
              position: 'top' // 标签的位置
            },
            // itemStyle: {
            //   color: '#5470c6', // 柱图颜色
            // },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#E0FFFF' },
                { offset: 0.5, color: '#83bff6' },
                { offset: 1, color: '#188df0' }
              ])
            }
          }
        ]
      }
      mybarChart.setOption(option)
    },
    async drawPieChart() {
      // 初始化饼状图
      const pieChart = echarts.init(this.$refs.pieChart, 'macarons')
      //过滤
      const filteredData = this.pieData.filter(item => item.value > 0)
      // 饼状图配置
      const pieOption = {
        // title: {
        //   text: '签到占比图', // 标题文本
        //   left: 'center', // 标题居中
        //   textStyle: {
        //     color: '#333', // 标题颜色
        //     fontSize: 18 // 标题字号
        //   }
        // },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          top: 'bottom',
          left: 'center',
          data: filteredData.map(item => item.name)
        },
        series: [
          {
            name: '占比',
            type: 'pie',
            roseType: 'radius',
            radius: [15, 95],
            center: ['50%', '38%'],
            // avoidLabelOverlap: false,
            // label: {
            //   show: false,
            //   position: 'center'
            // },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: '30',
            //     fontWeight: 'bold'
            //   }
            // },
            // labelLine: {
            //   show: false
            // },
            data: filteredData,
            animationEasing: 'cubicInOut',
            animationDuration: 2600
          }
        ]
      }
      // 设置饼状图配置
      pieChart.setOption(pieOption)
    }
  }
}
</script>
<style lang="scss" scoped>
.attendance-container {
  padding: 20px 20px 0 20px;
  #frozen-btn {
    button {
      border: 0;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 10px;
      border-radius: 4px;
      color: white;
      outline: none;
      position: relative;
      margin-bottom: 5px;
    }

    button:before {
      content: '';
      display: block;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0.4) 50%
      );
      background-size: 210% 100%;
      background-position: right bottom;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 4px;
      transition: all 1s;
      -webkit-transition: all 1s;
    }
    .green {
      background-image: linear-gradient(to right, #25aae1, #40e495);
      box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    }
    .green:hover:before {
      background-position: left bottom;
    }
  }

  .course-attendance {
    padding: 10px;
    .course-title {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
    }
    .statistics-layout {
      border: 1px solid #dcdfe6;
      .layout-title {
        color: #787f8d;
        padding: 15px 20px;
        background: #f2f6fc;
        font-weight: bold;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .right-part {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px auto;
        height: 400px;
        .grade {
          margin-top: 20px;
          .grade-one {
            font-size: 25px;
            color: #5fcb71;
          }
          .grade-two {
            font-size: 25px;
            color: #4c9df6;
          }
          .grade-three {
            font-size: 25px;
            color: #daa44f;
          }
          .grade-four {
            font-size: 25px;
            color: #ea5851;
          }
        }
      }
    }
  }
}
</style>
